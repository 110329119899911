import { makeStyles } from '@material-ui/core/styles'
import {
  AlertDialog,
  ConfirmationDialog,
  FormSection,
  FormSectionTitle,
  LearnMoreLink,
  PageHelpMessage,
  Typography,
  useDialogs,
  useOnboardingContext
} from '@ui/stickybid'
import type { TermsOption } from '@paintscout/util/builder'
import {
  copyTermsOption,
  createTermsOption,
  deleteTermsOption,
  getFeature,
  getObjectLabels,
  getTermsOptions,
  reorderTermsOptions
} from '@paintscout/util/builder'
import { useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React, { useState } from 'react'
import EditTermsOptionDialog from '../../dialogs/EditTermsOptionDialog'
import OptionsTileList from '../../OptionsTileList'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

const useStyles = makeStyles((theme) => ({
  root: {},
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 5, 0, 0)
  },
  title: {
    padding: 0
  }
}))

export interface TermsOptionsProps {
  onDialogConfirm?: () => void
}

function TermsOptions({ onDialogConfirm }: TermsOptionsProps) {
  const classes = useStyles({})
  const { openDialog, dismissDialog } = useDialogs()
  const [quoteType, setQuoteType] = useState('all')
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options, rates } = values

  const { updateOptionsOnboard } = useOnboardingContext()
  const isFirstTermsUpdate = !options.options.onboardingTasks?.termsUpdated

  const objectLabels = getObjectLabels({ options })
  const items = getTermsOptions({ options, quoteType: quoteType, inactive: true })
  const hasMultipleTerms = !!getFeature({ options, path: 'quotes.multipleTerms' })
  const defaultItem = options.options.terms.defaults.all

  return (
    <div className={classes.root}>
      <PageHelpMessage
        path="settings-terms"
        openContent={
          <>
            <div className={classes.titleRow}>
              <FormSectionTitle title={'Terms'} variant="h2" classes={{ root: classes.title }} />
              {/* <Select variant={'outlined'} onChange={handleQuoteTypeChange} value={quoteType}>
                <MenuItem value="all">All {objectLabels.quote.value} Types</MenuItem>
                {quoteTypes
                  .filter((quoteType: QuoteTypeOption) => {
                    return quoteType.key !== 'all'
                  })
                  .map((quoteType: QuoteTypeOption) => {
                    return (
                      <MenuItem key={quoteType.key} value={quoteType.key}>
                        {quoteType.label}
                      </MenuItem>
                    )
                  })}
              </Select> */}
            </div>
            <Typography>
              Here you can add your company contract(s).{' '}
              <LearnMoreLink link="https://www.stickybid.com/videos/#settings-terms" />
            </Typography>
            <Typography></Typography>
            <Typography>
              <em>
                P.S. make sure to select a default contract on your quote type, so it pre-populates for you on the
                quote!
              </em>
            </Typography>
          </>
        }
        closedContent={
          <div className={classes.titleRow}>
            <FormSectionTitle title={'Terms'} variant="h2" classes={{ root: classes.title }} />
            {/* <Select variant={'outlined'} onChange={handleQuoteTypeChange} value={quoteType}>
              <MenuItem value="all">All {objectLabels.quote.value} Types</MenuItem>
              {quoteTypes
                .filter((quoteType: QuoteTypeOption) => {
                  return quoteType.key !== 'all'
                })
                .map((quoteType: QuoteTypeOption) => {
                  return (
                    <MenuItem key={quoteType.key} value={quoteType.key}>
                      {quoteType.label}
                    </MenuItem>
                  )
                })}
            </Select> */}
          </div>
        }
      />
      <FormSection>
        <OptionsTileList
          title={''}
          useThumbnails={false}
          items={items.map((item) => ({
            ...item,
            key: item.name,
            icon: hasMultipleTerms && item.name === defaultItem ? StarIcon : null
          }))}
          createTitle={hasMultipleTerms ? 'Add New Terms Document' : null}
          showCreate={hasMultipleTerms}
          onReorder={hasMultipleTerms ? handleReorder : null}
          onEditItem={handleEditItem}
          onDeleteItem={hasMultipleTerms ? handleDeleteItem : null}
          onCopyItem={hasMultipleTerms ? handleCopyItem : null}
          onCreateClick={hasMultipleTerms ? handleCreateItem : null}
          actions={
            hasMultipleTerms
              ? [{ key: 'set-default', label: 'Set As Default', icon: StarBorderIcon, omittedItems: [defaultItem] }]
              : null
          }
          onItemAction={hasMultipleTerms ? handleItemActions : null}
        />
      </FormSection>
    </div>
  )

  // function handleQuoteTypeChange(ev: React.ChangeEvent<HTMLSelectElement>) {
  //   const { value } = ev.target
  //   setQuoteType(value)
  // }

  function handleItemActions(ev: any, key: string, actionName: string) {
    if (actionName === 'set-default') {
      const updatedOptions: OptionsDocument = {
        ...options,
        options: {
          ...options.options,
          terms: { ...options.options.terms, defaults: { ...options.options.terms.defaults, all: key } }
        }
      }
      setFieldValue('options', updatedOptions)
    }
  }

  function handleReorder(termsOptions: TermsOption[]) {
    const updatedOptions = reorderTermsOptions({ termsOptions, options: options })
    setFieldValue('options', updatedOptions)
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getTermsOptions({ options, inactive: true })
    const item = items.find((i) => i.name === key)

    openDialog(EditTermsOptionDialog, {
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        dismissDialog()
        setFieldValue(
          'options',
          isFirstTermsUpdate
            ? updateOptionsOnboard({ key: 'termsUpdated', value: true, options: updatedOptions })
            : updatedOptions
        )
        if (onDialogConfirm && isDirty) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleCreateItem(event: React.MouseEvent) {
    const item = createTermsOption()

    openDialog(EditTermsOptionDialog, {
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument) => {
        setFieldValue('options', updatedOptions)
        dismissDialog()
        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteItem(event: any, key: string) {
    if (key === defaultItem) {
      openDialog(AlertDialog, {
        title: 'Cannot Delete Default Terms',
        message: 'Please set another terms document as default before deleting.',
        onConfirm: (ev: React.MouseEvent<HTMLElement>) => {
          dismissDialog()
        }
      })

      return
    }

    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>These Terms will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
        </>
      ),
      onConfirm: (ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getTermsOptions({ options: options, inactive: true })
        const updatedOptions = deleteTermsOption({ termsOption: items.find((i) => i.name === key), options })

        setFieldValue('options', updatedOptions)
      },
      onCancel: (ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleCopyItem(event: any, key: string) {
    const updatedOptions = copyTermsOption({ options, key })
    setFieldValue('options', updatedOptions)
  }
}

export default TermsOptions
