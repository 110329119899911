import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import type { Action } from '@ui/stickybid'
import { TileList } from '@ui/stickybid'

import EditIcon from '@material-ui/icons/EditOutlined'
import { DeleteIcon } from '@ui/core/icons'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'

import type { Axis } from 'react-sortable-hoc'

import numeral from 'numeral'

const styles = (theme: Theme) => {
  const { pxToRem } = theme.typography
  return createStyles({
    root: {},
    tiles: {}
  })
}

export interface OptionsTileListProps extends WithStyles<typeof styles> {
  title: string
  subtitle?: string
  items: any[]
  createTitle?: string
  showCreate?: boolean
  disabled?: boolean
  actions?: Action[]
  includeUnitPrice?: boolean
  useThumbnails?: boolean

  onCreateClick?: (event: React.MouseEvent<HTMLElement>) => void
  onReorder?: (items: any) => void
  onEditItem?: (event: any, key: string | number) => void
  onCopyItem?: (event: any, key: string | number) => void
  onDeleteItem?: (event: any, key: string | number) => void
  onItemAction?: (event: any, key: string | number, action: string) => void

  permanent?: string[]

  noItemTitle?: string
  noItemHelpText?: string

  axis?: Axis
}

class OptionsTileList extends React.Component<OptionsTileListProps> {
  constructor(props: OptionsTileListProps) {
    super(props)
  }

  public render() {
    const {
      items,
      title,
      subtitle,
      classes,
      showCreate,
      permanent,
      axis,
      disabled,
      actions,
      includeUnitPrice,
      useThumbnails = true
    } = this.props

    const editAction = {
      key: 'edit',
      label: 'Edit',
      icon: EditIcon
    }

    const copyAction = {
      key: 'copy',
      label: 'Duplicate',
      icon: CopyIcon
    }

    const deleteAction = {
      key: 'delete',
      label: 'Delete',
      icon: DeleteIcon
    }
    return (
      <TileList
        classes={{
          tiles: classes.tiles
        }}
        items={
          includeUnitPrice
            ? items.map((item) => {
                if (!item.hideQuantity) item.sublabel = `$${numeral(item.pricePerUnit).format('0,0[.]00')} / Unit`
                return item
              })
            : items
        }
        useThumbnails={useThumbnails}
        disabled={disabled}
        title={title}
        subtitle={subtitle}
        draggable={!!this.props.onReorder}
        showActive={true}
        dragAxis={axis}
        actions={(item) => {
          if (item.label === 'Default' || (permanent && permanent.includes(item.key as string))) {
            return [editAction]
          } else {
            return [
              this.props.onEditItem ? editAction : null,
              this.props.onCopyItem ? copyAction : null,
              this.props.onDeleteItem ? deleteAction : null,
              ...(actions
                ? actions.map((action) => {
                    if (action.omittedItems?.includes(item.key as string)) {
                      return null
                    } else {
                      return action
                    }
                  })
                : [])
            ].filter((item) => item)
          }
        }}
        createButton={this.props.createTitle}
        onSelect={this.props.onEditItem}
        onActionClick={this.handleActionClick}
        onReorder={this.props.onReorder}
        onCreate={this.props.onCreateClick}
        noItemTitle={this.props.noItemTitle}
        noItemHelpText={this.props.noItemHelpText}
        subLabelField={'sublabel'}
      />
    )
  }

  public handleActionClick = (event: React.MouseEvent<HTMLElement>, actionName: string, key: string | number) => {
    if (actionName === 'edit') {
      this.props.onEditItem(event, key)
    } else if (actionName === 'copy') {
      this.props.onCopyItem(event, key)
    } else if (actionName === 'delete') {
      this.props.onDeleteItem(event, key)
    } else if (this.props.onItemAction) {
      this.props.onItemAction(event, key, actionName)
    }
  }
}

export default withStyles(styles)(OptionsTileList)
