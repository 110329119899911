import React, { useEffect } from 'react'
import type { Theme } from '@material-ui/core/styles'
import { Hidden, makeStyles } from '@material-ui/core'

import {
  Checkbox,
  Grid,
  HtmlEditor,
  InputField,
  InputLabel,
  KeypadInputField,
  Switch,
  Typography,
  useClientOptions
} from '@ui/stickybid'

import type { UpdateableLineItem } from '@paintscout/util/builder'
import { getFeature } from '@paintscout/util/builder'
import type { FormikErrors, FormikTouched } from 'formik'

export interface ItemFormProps {
  isOptions?: boolean
  newItem?: boolean
  values?: UpdateableLineItem
  errors?: FormikErrors<any>
  touched?: FormikTouched<any>

  handleChange?: (e: React.ChangeEvent<any>) => void
  setFieldValue?: (field: string, value: any) => any
  handleSwitch?: (target: string) => void
  onChange?: (lineItem: UpdateableLineItem) => void
  mediaComponent?: React.ReactNode
}

const useStyles = makeStyles<Theme, ItemFormProps>(
  (theme) => ({
    root: {},
    imageWrapper: {
      alignContent: 'flex-end',
      maxWidth: 160
    },
    imageLabel: {
      marginLeft: theme.spacing(1),
      position: 'relative',
      top: 6
    },
    switchItem: {
      marginLeft: theme.spacing(-1)
    },
    checkboxItem: {
      alignItems: 'center',
      display: 'flex',
      marginLeft: theme.spacing(-1)
    },
    htmlEditorRoot: {
      cursor: 'text',
      '& .ProseMirror': {
        height: 200,
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
          maxHeight: 900,
          height: '30vh'
        },
        ['@media (max-width: 323px)']: {
          height: '24vh'
        }
      }
    },
    quantityPriceBlock: {
      ['@media (max-width: 323px)']: {
        flexGrow: 0,
        flexBasis: '100%',
        margin: 0
      }
    },
    quantityPriceSubBlock: {
      '&$quantityPriceSubBlock': {
        ['@media (max-width: 323px)']: {
          padding: 0
        }
      }
    }
  }),
  { name: 'ItemForm' }
)

function ItemForm({
  isOptions,
  values,
  errors,
  touched,
  handleChange,
  handleSwitch,
  setFieldValue,
  mediaComponent,
  newItem,
  ...props
}: ItemFormProps) {
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const hasOptionalItems = getFeature({ options, path: 'quotes.optionalItems' })
  const fromPdfImport = values.fromPdfImport

  useEffect(() => {
    if (!values.quantity && newItem) {
      setFieldValue('quantity', 1)
    }
  }, [])

  const nameField = (
    <InputField
      autoFocus={newItem ? true : false}
      fullWidth
      helperText={errors.label && touched.label && errors.label}
      helperTextState={errors.label ? 'error' : 'disabled'}
      inputProps={{ maxLength: 50 }}
      label={'Name'}
      name={'label'}
      onChange={handleChange}
      placeholder={'Product or Service Name'}
      value={values.label}
    />
  )

  return (
    <>
      <Hidden smUp>
        <Grid item xs={12}>
          {nameField}
        </Grid>
      </Hidden>

      <Grid container spacing={2} item xs className={classes.quantityPriceBlock}>
        <Hidden xsDown>
          <Grid item xs={12}>
            {nameField}
          </Grid>
        </Hidden>
        <Grid item xs={12} className={classes.quantityPriceSubBlock}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <KeypadInputField
                fullWidth
                label={'Quantity'}
                name={'quantity'}
                fixedDecimalScale={false}
                onChange={handleChange}
                placeholder={'No.'}
                value={values.quantity}
                disabled={fromPdfImport}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeypadInputField
                fullWidth
                label={'Unit Price ($)'}
                name={'pricePerUnit'}
                onChange={handleChange}
                placeholder={'0.00'}
                value={values.pricePerUnit}
                disabled={fromPdfImport}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} item xs className={classes.imageWrapper}>
        <InputLabel className={classes.imageLabel}>Feature Image</InputLabel>
        {mediaComponent}
      </Grid>
      <Grid item xs={12}>
        <HtmlEditor
          label={'Description'}
          value={values.description}
          onChange={(value: string) => {
            setFieldValue('description', value)
          }}
          classes={{ root: classes.htmlEditorRoot }}
          noHeaders
          fullWidth
          multiline
          bubbleMenuDisabled
          floatingMenuDisabled
          menuBarEnabled
        />
      </Grid>
      {!isOptions && hasOptionalItems && !fromPdfImport && (
        <Grid item xs className={classes.checkboxItem}>
          <Checkbox
            checked={values.section === 'options'}
            onClick={() => setFieldValue('section', values.section === 'options' ? 'bid' : 'options')}
          />
          <Typography variant={'body1'}>Optional</Typography>
        </Grid>
      )}
      {isOptions && (
        <Grid item xs className={classes.switchItem}>
          <Switch name={'active'} checked={Boolean(values.active)} onChange={() => handleSwitch('active')} />
          Active
        </Grid>
      )}
    </>
  )
}

export default ItemForm

{
  /* <Grid item xs={6} sm={4}>
                <InputField
                  name="taxRate"
                  label="Custom Tax (%)"
                  placeholder={'GST 5'}
                  value={values.taxRate}
                  onChange={handleChange}
                  fullWidth
                  endAdornment={'%'}
                  helperText={errors.taxRate && touched.taxRate && errors.taxRate}
                  helperTextState={errors.taxRate ? 'error' : 'disabled'}
                />
              </Grid> */
}

{
  /* <Grid item xs={12} sm={6} md={4}>
<EyeballToggle
  name="showPrice"
  edge={'start'}
  checked={Boolean(values.showPrice)}
  onClick={() => handleSwitch('showPrice')}
  label={'Show Unit Price to Client'}
/>
</Grid> */
}
